// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@500&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@500&family=Lato&family=Open+Sans&display=swap');


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.svg-icon{
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;    
}

.card-body img {
    max-width:100%;
    height:auto;
}

.card{
    //border-top:6px solid $pink;
    border-radius:0;
    .card-title{
        border-bottom:6px solid $pink;
    }
}

.large-logo{
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
        width:600px;
    }
}

.programme-intro, .page-copy{
    background: rgba(255,255,255,0.8);
    //background:black;
    &:before{
        display:block;
        content:"";
        height:12px;
        background: $pink;
    }

    h1 {
        text-align: center;
        margin-bottom: 3rem;
        &:after{
            max-width:400px;
            display:block;
            content:"";
            height:4px;
            position: relative;
            top:1.4rem;
            background: $pink;
            margin-left: auto;
            margin-right: auto;
        }
    }
    line-height:2rem;
    margin-bottom:3rem;
    text-align: left;
    > div {
        padding:2rem;
        max-width:900px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.page-copy{
    padding-bottom: 40px;
    a{
        color:darken($pink,20);
    }
}

hr {
    border-top: 3px solid white;
}

@keyframes intro {
  0% {
    opacity: 0;
    transform:translate(0,20px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform:translate(0,0);
  }
}

.programme-intro {
    text-align: center;
    @include media-breakpoint-down(sm) {
        opacity: 0;
        animation: intro ease 4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}

@mixin display-font{
    text-transform: uppercase;
    font-family: 'PT Serif', serif;
    letter-spacing: 3px;
    //font-family: 'Cormorant Infant', serif;

}
body {
    color: black;
    background-image: url('/images/background-2.png');
    animation: shrink 10s infinite alternate;
}

@keyframes shrink {
  0% {
    background-position: 0 10px;
  }
  100% {
    background-position: 10px 0;
  }
}

.main-illustration{
    padding-top: 70px;
    opacity: 0.8;
    @include media-breakpoint-up(md) {
        animation: fade 20s ease-in-out infinite;
    }
    @include media-breakpoint-up(lg) {
        padding-top:0;
    }
}


@keyframes fade {
  0%,100% { opacity: 0.6 }
  50% { opacity: 0.8 }
}

.main-nav {
    margin:0;
    padding:0;
    .dropdown-toggle{
        padding-left:0.5rem;
        padding-right:0;
        &:after{
            display: none;
        }
    }
    @include display-font;
    li{list-style: none;}

    a, .dropdown-item{
        text-decoration: none;
        font-size:17px;

        &.small {
            font-size: 15px;
        }
    }
    a {
        color:white;
        font-size:26px;
    }
    .dropdown-item:active {
        background-color: $pink;
    }
    .dropdown-item, .dropdown-item a {
        color:black;
        i{color:$pink;}
        @include media-breakpoint-up(sm) {
            font-size:18px;
        }
    }
}
.navbar-sn {
    @include media-breakpoint-down(md) {
        padding-left:0;
        padding-right: 0;
    }

  padding-top:10px;
  padding-bottom:10px;
  .dropdown-menu{
      opacity: 0.9;
      margin-top:0;
      border:none;
      border-radius: 0;
      .nav-item{
          padding-top:14px;
          padding-bottom:14px;
      }
  }
}

.maybe-break{
    visibility: hidden;
    display:block;
    height:0;
    @media (min-width: 1400px) {
        visibility: visible;
        display:inline;
        height:auto;
    }
}

.page-list > a{
    &:hover{
        text-decoration: none;
    }
}

.trix-wrapper{
    h1{
        font-size:1.6rem;
        margin-bottom:2rem;
        &:after{
            width:100%;
        }
    }
    figure{
        text-align: center;
        img{max-width: 100%; height: auto;}
         a{
            display:inline-block;
            pointer-events: none;
            figcaption{
                font-style: italic;
                text-align: left;
                .attachment__size, .attachment__name{display: none}
            }
        }
    }
}

input[type=file].form-control{
    height:auto;
}

.sn-logo {
    max-width:80%;
    display:inline-block;
    width:340px;
    padding-top:35px;
    padding-bottom:35px;
}

h1,h2,h3,h4,h5,h6, .h1,.h2,.h3,.h4,.h5,.h6,{
    @include display-font;
}

@include media-breakpoint-down(md) {
    h1,.h1{
        font-size:1.7rem;
    }
    h2,.h2{
        font-size:1.5rem;
    }
    h3,.h3{
        font-size:1.4rem;
    }
    h4,.h4{
        font-size:1.3rem;
    }
    h5,.h5{
        font-size:1.2rem;
    }
    h6,.h6{
        font-size:1.1rem;
    }
}
p{margin-bottom:1.5em;}

.programme-entries{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    .programme-entry {
        margin-bottom:30px;
        &:not(.active) .card{cursor:pointer;}
        .card{
            overflow:hidden;
            .teaser {
                .card-img-bottom{
                    opacity:0.9;
                    filter:grayscale(1) contrast(2);
                }
                .programme-strand{
                    bottom:0;
                    font-size: 16px;
                    @include media-breakpoint-down(sm) {font-size: 1em;}
                }
                .card-title {
                    top:0;
                    text-align: center;
                    font-size: 26px;
                    @include media-breakpoint-down(sm) {font-size: 32px;}
                }
                .programme-strand, .card-title {
                    z-index: 2;
                    left:0;
                    right:0;
                    background: rgba(255,255,255,0.7);
                }
            }
        }
    }
}
.mobile-detail .embed-responsive{
    padding-bottom: 75%;
}

#programme-focus{
    background:white;
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    z-index:999;
    background: rgba(white,0.95);
    display:none;
    .close-programme-entry{
        cursor:pointer;
    }
    &.active{
        display:block;
    }
    .video-wrapper{
        position: relative;
        overflow: hidden;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    @include media-breakpoint-down(sm) {
        &.active{
            top:0;
        }
        .programme-focus-inner{
            position:absolute;
            top:30px;
            left:30px;
            right:15px;
            padding-right:15px;
            bottom:30px;
            overflow:auto;
        }
        .programme-description{
            margin-bottom:30px;
        }
        .video-wrapper{
            width:100%;
            padding-bottom: 56.25%;
            height: 0;
            margin-bottom:30px;
        }
        .close-programme-entry{
            position:fixed;
            top:50%;
            transform: translate(0, -50%);
            right:0;
            background:white;
            border-radius:50% 0 0 50%;
            padding:13px 5px 11px 13px;
            border-left:2px solid black;
            border-top:2px solid black;
            border-bottom:2px solid black;
            line-height: 1rem;
            color:$pink;
        }
    }
    @include media-breakpoint-up(md) {
        border-top: 12px solid $pink;
        padding-top:30px;
        padding-bottom:30px;
        background: rgba(255,255,255,0.9);
        .programme-focus-inner{
            display: flex;
            width: 1400px;
            max-width:96%;
            margin-left: auto;
            margin-right: auto;
        }
        img, .video-wrapper{
            height:225px;
            width:auto;
            flex-shrink: 0;
            align-self:center;
        }
        .video-wrapper{
            width:400px;
        }
        .programme-description {
            height:310px;
            margin-right: 15px;
            padding-right: 15px;
            overflow:auto;
        }
        .close-programme-entry{
            position:absolute;
            top:7px;
            right:7px;
        }
    }
    @include media-breakpoint-up(lg) {
        img, .video-wrapper{
            height:310px;
        }
        .video-wrapper{
            width:550px;
        }
    }
    @include media-breakpoint-up(xl) {
        .close-programme-entry{
            position:static;
            margin-left: 15px;
        }
    }
}
.has-active-programme-entry{
    @include media-breakpoint-down(sm) {
        overflow:hidden;
    }
}

.sponsors-bar{
    border-top:3px white solid;
    padding-top: 40px;
    margin-top: 60px;
    margin-bottom: 40px;
    h5{
        margin-bottom:30px;
    }
    .logos{
        text-align: center;
        a{
            display: inline-block;
            padding-left: 2%;
            padding-right: 2%;
        }
        img{
            height:45px;
            &.stout {
                height:84px
            }
            @include media-breakpoint-down(md) {
                height:35px;
                &.stout {
                    height:60px
                }
                &.semi-stout {
                    height:40px
                }
            }
            @include media-breakpoint-down(xs) {
                height:20px;
                &.stout {
                    height:45px
                }
                &.semi-stout {
                    height:32px
                }
            }
        }
    }

}

.footer-buttons{
    padding-top:40px;
    text-align: right;
}

.main-panel {
    z-index:2;
    position:relative;
    .card {
        background: none;
        .card-body{
            background:rgba(white, 0.85);
        }
    }
}
